import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const about = () => (
    <Layout>
      <SEO title="About Us" />
   
      <div className="Herosub batslogo">
  <div className="HeroGroupsmall">
    <p>&nbsp;</p>
<h1>About Us</h1></div>
  </div>

<div className="mainContent">
    <p className="center">
        <div className="responsive">
<iframe allowfullscreen src="https://www.youtube.com/embed/TsQB7AZAfUE?feature=oembed&amp;autoplay=1&amp;start=0&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0" width="600" height="350"></iframe>		
</div></p>
<p>&nbsp;</p><p>&nbsp;</p>
<h2 style={{textAlign: "center"}}>Meet the Coaches</h2>
<div className="coachGrid">
  <div><img width="150px" src={require("../images/Melvin Brown II.png")} /><h3>Melvin Brown II</h3><p>Head Varsity Coach</p></div>
  <div><img width="150px"src={require("../images/Rich Guastadisegni.png")} /><h3>Rich Guastadisegni</h3><p>Assistant Varsity Coach</p></div>
  <div><img width="150px"src={require("../images/Vernon Perry.png")} /><h3>Vernon Perry</h3><p>Assistant Varsity Coach</p></div>
  <div><img width="150px"src={require("../images/Curtis Brockman.png")} /><h3>Curtis Brockman</h3><p>Head JV Coach</p></div>
  <div><img width="150px"src={require("../images/Keith Crim.png")} /><h3>Keith Crim</h3><p>Assistant JV Coach</p></div>
  <div><img width="150px"src={require("../images/Montrel Tennessee.png")} /><h3>Montrel Tennessee</h3><p>Recruiting Coordinator</p></div>
</div>
    </div>
    </Layout>
  )
  
  export default about